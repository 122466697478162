.networkHolder {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.network {
  background: #2b2b2a;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.networkName {
  color: #efefef;
  font-size: 18px;
  line-height: 24px;
  display: flex;
}
