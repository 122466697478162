.pendingHolder {
  padding-top: 123px;
  padding-bottom: 145px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 460px) {
    padding-bottom: 190px;
  }
  img {
    width: 100px;
    height: 100px;
    display: block;
    margin-bottom: 20px;
    animation: scale-down-center 3s infinite;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    color: #999;
    margin: 0;
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
