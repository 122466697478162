.headingHolder {
  display: flex;
  justify-content: space-between;
  span {
    cursor: pointer;
  }
}
.inputRow {
  display: flex;
  margin-bottom: 21px;
}
.labelRow {
  display: flex;
  justify-content: space-between;
  label {
    color: #999;
    display: block;
    margin-bottom: 5px;
    font: 14px/20px 'General Sans';
  }
}
.center {
  display: flex;
  justify-content: center;
  margin-bottom: 9px;
  margin-top: -3px;
}
.button {
  width: 100%;
  height: 48px;
  text-align: center;
  color: #171717;
  font: 16px/22px 'General Sans-semibold';
  background: rgba(239, 239, 239, 0.5);
  border: 0;
  border-radius: 12px;
}
.buttonDefault {
  background: rgba(239, 239, 239, 0.5);
}
.buttonActive {
  background: #efefef;
}
.infoBtnRow {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 460px) {
    flex-direction: row;
    margin-bottom: 20px;
  }
}
.networkBlock {
  .ttl {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #999;
  }
}
