@import './_variables.scss';
@import './_fonts.scss';

*,
*:after,
*:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'General Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 360px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  padding: 0;
  margin: 0;
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: 'General Sans';
}
#root {
  min-height: 100vh;
}

.wrapper {
  margin: auto;
  max-width: 1136px;
  z-index: 100;
  height: 100%;

  @media (min-width: 768px) {
    padding-bottom: 30px;
    height: auto;
  }
}

.wrapperBackground {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -10;
  width: 100vw;
  height: 100vh;
  background: url(../img/app/background.svg) no-repeat 50% 0;
  background-size: cover;
}

.starsBackground {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -10;
  width: 100vw;
  height: 100vh;
  background: url(../img/app/Stars.png) no-repeat 50% 0;
  background-size: cover;
}

.toast {
  .Toastify {
    &__toast-container {
      width: 340px !important;
    }

    &__toast {
      border-radius: 20px;
      padding: 0;
      background-color: #232323;
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #efefef;
    }

    &__toast-body {
      padding: 25px 27px;
    }

    &__toast-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
