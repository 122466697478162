$transition: all 0.2s ease;

.tooltip {
  background-color: var(--color-grey-900) !important;
  min-height: 362px !important;
  color: var(--color-white) !important;
  border-radius: 20px !important;
  z-index: 10000 !important;
  opacity: 1 !important;
  padding: 17px 30px 20px 21px !important;
  margin-top: 20px;
  max-width: 100vw;
  width: 100vw !important;
  min-height: calc(100vh - 90px) !important;

  @media (min-width: 768px) {
    min-width: 420px !important;
    min-height: auto !important;
    width: auto !important;
    margin-top: 0px;
    padding: 25px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__list {
    overflow: auto;
    height: 400px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: var(--color-grey-900);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey);
      border-radius: 10px;
    }
  }

  &__content {
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-white);
  }

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
  }

  &__account {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    margin: 18px 0 30px 0;

    @media (min-width: 768px) {
      margin: 20px 0 30px 0;
      gap: 10px;
    }
  }

  &__avatarContainer {
    width: 42px;
    height: 42px;
    background-color: var(--color-dark);
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__info {
    width: 100%;
  }

  &__connected {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-grey);

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__address {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
  }

  &__disconnect {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-white);
    border: 1px solid var(--color-grey-800);
    border-radius: 12px;
    padding: 10px 13px;
    transition: $transition;
    width: max-content;

    &:hover {
      background-color: var(--color-dark);
    }

    @media (min-width: 768px) {
      width: auto;
      padding: 10px 20px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__label {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-white);
    margin-bottom: 20px;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-top: 44px;
  }

  &__emptyIcon {
    width: 40px;
    height: 40px;
    color: var(--color-grey);
  }

  &__emptyHeading {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
    text-align: center;
    margin-top: 15px;
  }

  &__emptySubheading {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-grey-200);
    text-align: center;
    margin-top: 7px;
  }

  &__copy {
    color: var(--color-grey);
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: $transition;

    &:hover {
      color: var(--color-white);
    }
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__iconContainer {
    background-color: var(--color-object-bg);
    width: 44px;
    height: 44px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 24px;
    height: 24px;

    &--pending {
      animation: scale-down-center 2s infinite;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__statusContainer {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__status {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
    margin: 0;
    font-family: 'General Sans';

    &--success {
      color: var(--color-green);
    }

    &--denied {
      color: var(--color-orange);
    }
  }

  &__time {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-grey);
  }

  &__linkIcon {
    width: 14px;
    height: 14px;
    color: var(--color-grey);
    transition: $transition;

    &:hover {
      color: var(--color-white);
    }
  }

  &__amount {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
    margin-left: auto;
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
