.button {
  background: #2b2b2a;
  border: none;
  color: #efefef;
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 0 14px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  font: 16px/21.6px 'General Sans';
  @media (min-width: 460px) {
    width: 180px;
    margin-bottom: 0;
  }
  svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
  em {
    font-style: normal;
    color: #999;
    font: 16px/21.6px 'General Sans';
  }
  div {
    display: flex;
    align-items: center;
  }

  span {
    max-width: 90px;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
