@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
  font-family: 'General Sans';
  src: local('GeneralSans-Medium.woff2'),
    url('../fonts/GeneralSans-Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans-semibold';
  src: local('GeneralSans-Semibold.woff2'),
    url('../fonts/GeneralSans-Semibold.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'General Sans-semibold';
  src: local('GeneralSans-Bold.woff2'),
    url('../fonts/GeneralSans-Bold.woff2') format('woff2');
  font-weight: 600;
}
