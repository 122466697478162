.header {
  margin-bottom: 0px;
  max-width: 1136px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 90px;
  align-items: center;
  @media (min-width: 460px) {
    margin-bottom: 40px;
  }
  @media (min-width: 1200px) {
    padding: 0;
  }
}
.labels {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding: 21px 0;
  align-items: center;
  @media (min-width: 768px) {
    padding: 0;
    gap: 20px;
  }
}
.logo {
  background: url(../../assets/img/app/logo-icn.svg);
  width: 44px;
  height: 44px;
  display: flex;
  @media (min-width: 768px) {
    width: 170px;
    height: 44px;
    background: url(../../assets/img/app/logo.svg);
  }
}
