.button__wrapper {
  position: relative;
}

.button {
  background: none;
  border: none;
  color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 13px 14px;
  border: 1px solid var(--color-grey-800);
  border-radius: 12px;
  position: relative;
  gap: 5px;
  height: 48px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  min-width: 167px;

  @media (min-width: 768px) {
    min-width: auto;
  }

  &:hover {
    background-color: var(--color-dark);
  }

  &:disabled {
    pointer-events: none;
    user-select: none;
    .button__text {
      opacity: 0.5;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__separator {
    width: 1px;
    height: 24px;
    background-color: var(--color-grey-800);
  }
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--color-white);
}
