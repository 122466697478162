@import '../../assets/styles/variables.scss';

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: $color-grey;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: $color-white;
  }
}

.checkboxDisabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.checkboxIcon {
  width: 20px;
  height: 20px;
}
