.button {
  width: 100%;
  height: 48px;
  text-align: center;
  color: #171717;
  font: 16px/22px 'General Sans-semibold';
  border: 0;
  border-radius: 12px;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: rgba(239, 239, 239, 0.5);
  }
}
.btnHolder {
  min-width: 100%;
}

.btnContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
