.inputDefaultHolder {
  position: relative;
  min-height: 56px;
  height: min-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #323232;
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 17px 14px 17px 14px;
}

.inputDefaultHolderError {
  border: 1px solid var(--color-error);
}

.inputDefaultHolderDisabled {
  pointer-events: none;

  .inputDefault {
    color: #8e8e8e;

    &::placeholder {
      color: #8e8e8e;
    }
  }
}

.inputDefaultWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

input.inputDefault {
  font-family: 'General Sans';
  background: none;
  font-size: 16px;
  line-height: 22px;
  color: #efefef;
  border: none;
  width: 100%;
  letter-spacing: -0.2px;
  transition: all 0.2s ease;
  padding: 0;
  margin: 0;

  &:focus-visible {
    outline: none;
  }

  &:focus {
    .inputDefaultHolder {
      border: 1px solid var(--color-white);
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

button.inputBtn {
  text-transform: uppercase;
  height: 24px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #000;
  background-color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: 8px;
  width: initial;
  position: absolute;
  top: 16px;
  right: 14px;
  padding: 4px 8px;
  font-family: 'General Sans';
}

.inputError {
  color: var(--color-error);
}
