.inputHolder {
  height: 69px;
  display: flex;
  align-items: center;
  position: relative;
  background: #1a1a1a;
  border: 1px solid #323232;
  border-radius: 8px 0 0 8px;
  width: 65%;

  svg {
    left: 13px;
    top: 34px;
    position: absolute;
    width: 20px;
    height: 20px;
  }
}
input.inputToken {
  padding-left: 40px;
  height: 69px;
  background: none;
  font-family: 'General Sans';
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  padding-top: 21px;
  width: 100%;
  border: none;
  cursor: default;
  &:focus-visible {
    outline: none;
  }
}
label.inputLabel {
  position: absolute;
  left: 14px;
  top: 9px;
  color: #999;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 20px;
}
