.button {
  background: none;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 13px 14px;
  border: 1px solid #484848;
  border-radius: 12px;
  gap: 5px;
  height: 48px;
  font: 16px/21.6px 'General Sans-semibold';
  transition: all 0.2s ease;

  @media (min-width: 460px) {
    padding: 13px 16px 13px 14px;
  }
  &:hover {
    background: #1a1a1a;
  }
  .textName {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
  }

  &__icon {
    animation: scale-down-center 2s infinite;
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
