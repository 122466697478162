.transactionResultHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    h1 {
      padding-top: 65px;
    }
  }
}
.transactionHeader {
  justify-content: center;
  display: flex;
  flex-grow: 0;
}
.transactionStats {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 31px;
  margin-top: 50px;
  [class*='NetworkName'] {
    gap: 0;
  }
}
.durationAmount {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 66px;
  top: -10px;
  justify-content: space-between;
  align-items: center;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: #323232;
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 16px;
    line-height: 22px;
  }
  .duration {
    color: #999;
  }
  .amount {
    color: #efefef;
  }
}
.resultText {
  color: #999;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
}

.button {
  margin-top: 80px;
}
