.wrapper {
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    height: 597px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  .title {
    margin: 0;
  }
}

.networkCard {
  display: flex;
  gap: 15px;
  cursor: pointer;
  margin-bottom: 20px;

  .imgWrapper {
    width: 44px;
    height: 44px;
    background: #2b2b2a;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img {
    width: 24px;
    height: 24px;
  }
  .networkName {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #efefef;
  }
}

.subTitle {
  color: #999999;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

.content {
  width: 100%;
  min-width: 100%;
  background: #232323;
  border-radius: 20px 20px 0 0;
  padding: 25px 26px;
  min-height: calc(100vh - 90px);

  h1 {
    font: 18px/24px 'General Sans-semibold';
    color: #efefef;
    margin: 0 0 20px;
  }

  @media (min-width: 460px) {
    width: 420px;
    min-width: 420px;
  }

  @media (min-width: 768px) {
    border-radius: 20px;
    min-height: auto;
    height: auto;
  }

  &__wrong {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    font-family: 'General Sans';
    color: var(--color-grey);
    text-align: center;
    width: 100%;
    margin-top: 20px;

    span {
      text-transform: capitalize;
    }
  }

  &__button {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: var(--color-white);
    text-align: center;
    width: max-content;
    margin: 0;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    transition: all 0.2s ease;

    &:hover {
      text-decoration: underline;

      .content__icon {
        transform: translateX(4px);
      }
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    transition: all 0.2s ease;
  }
}
