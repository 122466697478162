main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: calc(100vh - 90px);

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    min-height: auto;
  }
}

.content {
  width: 100%;
  min-width: 100%;
  background: #232323;
  border-radius: 20px 20px 0 0;
  padding: 25px 26px;
  min-height: calc(100vh - 90px);

  h1 {
    font: 18px/24px 'General Sans-semibold';
    color: #efefef;
    margin: 0 0 20px;
  }

  @media (min-width: 460px) {
    width: 420px;
    min-width: 420px;
  }

  @media (min-width: 768px) {
    border-radius: 20px;
    min-height: auto;
    height: auto;
  }

  &__wrong {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    font-family: 'General Sans';
    color: var(--color-grey);
    text-align: center;
    width: 100%;
    margin-top: 20px;

    span {
      text-transform: capitalize;
    }
  }

  &__button {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: var(--color-white);
    text-align: center;
    width: max-content;
    margin: 0;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    transition: all 0.2s ease;

    &:hover {
      text-decoration: underline;

      .content__icon {
        transform: translateX(4px);
      }
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    transition: all 0.2s ease;
  }
}
