.receive {
  border: 1px solid #323232;
  border-radius: 8px;
  padding: 14px;
  color: #999;
  margin-bottom: 40px;

  &__totatContainer {
    margin-bottom: 20px;
  }

  &__row {
    display: grid;
    grid-template-columns: 50% 50%;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__mainLabel {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
  }

  &__total {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    justify-self: flex-end;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__label {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
  }

  &__value {
    justify-self: flex-end;
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
