.headingHolder {
  display: flex;
  justify-content: space-between;
  span {
    cursor: pointer;
  }
}
.inputRow {
  display: flex;
  margin-bottom: 21px;
}
.labelRow {
  display: flex;
  justify-content: space-between;
  label {
    color: #999;
    display: block;
    margin-bottom: 5px;
    font: 14px/20px 'General Sans';
  }
}
.center {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 28px;
}

.checkbox {
  margin: 18px 0;
}

.swapButton {
  width: 24px;
  height: 24px;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: rotate(-180deg);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
