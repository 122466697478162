$color-grey: #999999;
$color-white: #efefef;

:root {
  --color-grey: #999999;
  --color-grey-200: #a8a8b2;
  --color-grey-800: #484848;
  --color-grey-900: #232323;
  --color-white: #efefef;
  --color-error: #f35656;
  --color-dark: #1a1a1a;
  --color-bg: #2b2b2a;
  --color-green: #56f3cd;
  --color-orange: #f3b456;
  --color-object-bg: #2b2b2a;

  --toastify-toast-width: 340px;
}
